import React from 'react';
import '../../assets/styles/footer.css';

const Footer = () => {
  // Add JSON-LD schema for better SEO
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Matrixit",
    "description": "Matrixit provides innovative solutions for your business needs, specializing in cutting-edge technology services and hardware solutions.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Link Rd, Surya Vihar",
      "addressLocality": "Cuttack",
      "addressRegion": "Odisha",
      "postalCode": "753012",
      "addressCountry": "IN"
    },
    "email": "info@matrixit.com",
    "telephone": "+15551234567",
    "sameAs": [
      "https://facebook.com",
      "https://twitter.com",
      "https://linkedin.com"
    ]
  };

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      <footer className="footer" itemScope itemType="https://schema.org/Organization">
        <div className="footer-content">
          <div className="footer-section about">
            <h3>About Us</h3>
            <p itemProp="description">
              Matrixit provides innovative solutions for your business needs, specializing in cutting-edge technology services and hardware solutions to drive efficiency and growth.
            </p>
            <div className="footer-logo">
              <span itemProp="name">Matrixit</span>
            </div>
          </div>
        </div>
        <div className="footer-section quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/products">Products</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h3>Contact</h3>
          <p>Email: <a href="mailto:info@matrixit.com" itemProp="email">info@matrixit.com</a></p>
          <p>Phone: <a href="tel:+15551234567" itemProp="telephone">(555) 123-4567</a></p>
          <p itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
            <span itemProp="streetAddress">Link Rd, Surya Vihar</span>,{' '}
            <span itemProp="addressLocality">Cuttack</span>,{' '}
            <span itemProp="addressRegion">Odisha</span>{' '}
            <span itemProp="postalCode">753012</span>
          </p>
        </div>
        <div className="footer-section follow-us">
          <h3>Follow Us</h3>
          <div className="social-links">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <span className="social-icon">📘</span> Facebook
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <span className="social-icon">🐦</span> Twitter
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <span className="social-icon">💼</span> LinkedIn
            </a>
          </div>
        </div>
        <div className="footer-section newsletter">
          <h3>Newsletter</h3>
          <p>Stay updated with our latest news and offers.</p>
          <form className="newsletter-form">
            <input
              type="email"
              placeholder="Enter your email"
              required
              aria-label="Email for newsletter"
            />
            <button type="submit" className="subscribe-button">Subscribe</button>
          </form>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} Matrixit. All rights reserved.</p>
      </div>
    </>
  );
};

export default Footer;