import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/styles/navbar.css';
import { CiMenuBurger } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (isProductsOpen) setIsProductsOpen(false); // Close submenu when toggling main menu
  };

  const toggleProductsMenu = () => {
    navigate('/products'); // Navigate to the Products page when clicking the Products link in the navigation bar
    // Toggle the submenu when clicking the Products link in the navigation bar
    setIsProductsOpen(!isProductsOpen);
  };

  const closeMenus = () => {
    setIsOpen(false);
    setIsProductsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/" className="logo" onClick={closeMenus}>MATRIX IT</Link>
        <button className="hamburger" onClick={toggleMenu} aria-label="Toggle menu">
          {isOpen ? (
            <IoMdClose />
          ) : (
            <>
              <CiMenuBurger />
            </>
          )}
        </button>
      </div>
      <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        <div className="menu-wrapper">
          <Link to="/" className="nav-link" onClick={closeMenus}>Home</Link>
          <Link to="/about" className="nav-link" onClick={closeMenus}>About</Link>
          <Link to="/services" className="nav-link" onClick={closeMenus}>Services</Link>
          <div className="nav-item products-menu">
            <button
              className="nav-link products-toggle"
              onClick={toggleProductsMenu}

              aria-label="Toggle Products Menu"
            >
              Products
              <span className="dropdown-arrow">▼</span>
            </button>
            <div className={`products-submenu ${isProductsOpen ? 'active' : ''}`}>
              <Link to="/products/enterprise" className="submenu-link" onClick={closeMenus}>
                Enterprise Solutions
              </Link>
              <Link to="/products/cloud" className="submenu-link" onClick={closeMenus}>
                Cloud Products
              </Link>
              <div className="submenu-section">
                <span className="submenu-title">Hardware Solutions</span>
                <Link to="/products/hardware/processors" className="submenu-link" onClick={closeMenus}>
                  Processors
                </Link>
                <Link to="/products/hardware/motherboards" className="submenu-link" onClick={closeMenus}>
                  Motherboards
                </Link>
                <Link to="/products/hardware/ram" className="submenu-link" onClick={closeMenus}>
                  RAM (Memory)
                </Link>
                <Link to="/products/hardware/graphics-cards" className="submenu-link" onClick={closeMenus}>
                  Graphics Cards
                </Link>
                <Link to="/products/hardware/storage" className="submenu-link" onClick={closeMenus}>
                  Storage
                </Link>
                <Link to="/products/hardware/power-supply" className="submenu-link" onClick={closeMenus}>
                  Power Supply
                </Link>
                <Link to="/products/hardware/cabinets" className="submenu-link" onClick={closeMenus}>
                  Cabinets
                </Link>
                <Link to="/products/hardware/cpu-coolers" className="submenu-link" onClick={closeMenus}>
                  CPU Coolers
                </Link>
                <Link to="/products/hardware/monitors" className="submenu-link" onClick={closeMenus}>
                  Monitors
                </Link>
                <Link to="/products/hardware/keyboards" className="submenu-link" onClick={closeMenus}>
                  Keyboards
                </Link>
                <Link to="/products/hardware/mouse" className="submenu-link" onClick={closeMenus}>
                  Mouse
                </Link>
                <Link to="/products/hardware/headphones" className="submenu-link" onClick={closeMenus}>
                  Headphones
                </Link>
                <Link to="/products/hardware/gaming-chairs" className="submenu-link" onClick={closeMenus}>
                  Gaming Chairs
                </Link>
                <Link to="/products/hardware/streaming-accessories" className="submenu-link" onClick={closeMenus}>
                  Streaming Accessories
                </Link>
                <Link to="/products/hardware/accessories" className="submenu-link" onClick={closeMenus}>
                  Accessories
                </Link>
                <Link to="/products/hardware/networking" className="submenu-link" onClick={closeMenus}>
                  Networking
                </Link>
                <Link to="/products/hardware/mini-pc" className="submenu-link" onClick={closeMenus}>
                  Mini PC
                </Link>
                <Link to="/products/hardware/fans" className="submenu-link" onClick={closeMenus}>
                  Fans
                </Link>
              </div>
            </div>
          </div>
          <Link to="/contact" className="nav-link" onClick={closeMenus}>Contact</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;