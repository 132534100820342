import React from 'react';
import '../assets/styles/home.css';
import SEOHelmet from '../components/common/SEOHelmet';

const Home = () => {
  return (
    <>
      <SEOHelmet
        title="Home"
        description="Matrixit - Transforming Ideas into Digital Reality. We provide innovative solutions for a connected world with cutting-edge technology services."
        keywords="digital transformation, technology solutions, innovation, IT services, software development"
        canonicalUrl="https://matrixit.com"
      />
      <div className="home-page">
        <section className="hero">
          <div className="hero-content">
            <h1>Welcome to Matrix IT</h1>
            <p>Transforming Ideas into Digital Reality</p>
            <p className="hero-subtitle">Innovative solutions for a connected world</p>
            <button className="hero-button">Discover More</button>
          </div>
          <div className="hero-overlay"></div>
        </section>

        <section className="features">
          <h2>Why Choose Matrix IT</h2>
          <div className="feature-grid">
            <div className="feature-card" data-animate="fade-up">
              <div className="feature-icon">🚀</div>
              <h3>Innovation</h3>
              <p>Cutting-edge solutions for modern challenges</p>
              <p className="feature-detail">Pushing boundaries with state-of-the-art technology</p>
            </div>
            <div className="feature-card" data-animate="fade-up" data-delay="200">
              <div className="feature-icon">👨‍💻</div>
              <h3>Expertise</h3>
              <p>Skilled team with years of industry experience</p>
              <p className="feature-detail">Certified professionals dedicated to your success</p>
            </div>
            <div className="feature-card" data-animate="fade-up" data-delay="400">
              <div className="feature-icon">🏆</div>
              <h3>Quality</h3>
              <p>Commitment to excellence in every project</p>
              <p className="feature-detail">Rigorous testing and quality assurance processes</p>
            </div>
            <div className="feature-card" data-animate="fade-up" data-delay="600">
              <div className="feature-icon">🤝</div>
              <h3>Support</h3>
              <p>24/7 customer support and maintenance</p>
              <p className="feature-detail">Ongoing assistance for your digital journey</p>
            </div>
          </div>
        </section>

        <section className="stats">
          <div className="stats-grid">
            <div className="stat-item" data-animate="count-up">
              <h3>500+</h3>
              <p>Projects Completed</p>
            </div>
            <div className="stat-item" data-animate="count-up" data-delay="200">
              <h3>98%</h3>
              <p>Client Satisfaction</p>
            </div>
            <div className="stat-item" data-animate="count-up" data-delay="400">
              <h3>50+</h3>
              <p>Team Members</p>
            </div>
          </div>
        </section>

        <section className="cta">
          <div className="cta-content" data-animate="fade-in">
            <h2>Ready to Start Your Project?</h2>
            <p>Let's work together to bring your vision to life</p>
            <p className="cta-subtitle">Contact us for a free consultation today</p>
            <button className="cta-button">Get Started Now</button>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;