import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEOHelmet = ({ title, description, keywords, canonicalUrl, imageUrl, pageType = 'website', font }) => {
  const siteTitle = 'Matrixit';
  const defaultDescription = 'Matrixit provides innovative solutions for your business needs, specializing in cutting-edge technology services and hardware solutions.';
  const siteUrl = 'https://matrixit.com';
  const defaultImage = `${siteUrl}/logo512.png`;
  
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': pageType === 'website' ? 'Organization' : 'WebPage',
    name: title ? `${title} | ${siteTitle}` : siteTitle,
    description: description || defaultDescription,
    url: canonicalUrl || siteUrl,
    logo: defaultImage,
    sameAs: [
      'https://twitter.com/matrixit',
      'https://linkedin.com/company/matrixit',
      'https://facebook.com/matrixit'
    ]
  };

  return (
    <Helmet>
      <html lang="en" />
      <title >{title ? `${title} | ${siteTitle}` : siteTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {font && <link rel="stylesheet" href={`https://use.typekit.net/${font}.css`} />}
      
      {/* Open Graph tags */}
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={title ? `${title} | ${siteTitle}` : siteTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:type" content={pageType} />
      <meta property="og:image" content={imageUrl || defaultImage} />
      {canonicalUrl && <>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
      </>}
      
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@matrixit" />
      <meta name="twitter:title" content={title ? `${title} | ${siteTitle}` : siteTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta name="twitter:image" content={imageUrl || defaultImage} />
      
      {/* JSON-LD structured data */}
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
    </Helmet>
  );
};

export default SEOHelmet;