import React from 'react';
import '../assets/styles/about.css';
import SEOHelmet from '../components/common/SEOHelmet';

const About = () => {
  return (
    <>
      <SEOHelmet
        title="About Us"
        description="Learn about Matrixit's journey from a Silicon Valley startup to a global leader in technology solutions. Discover our mission, values, and commitment to digital transformation."
        keywords="about matrixit, technology company, digital transformation, IT solutions, global tech leader, Silicon Valley startup"
        canonicalUrl="https://matrixit.com/about"
      />
    <div className="about-page">
      <section className="about-hero">
        <div className="container">
          <h1>About Matrix IT</h1>
          <p>Leading the way in digital transformation</p>
          <p className="hero-subtitle">
            Pioneering innovative solutions since 2010, transforming businesses worldwide
          </p>
          <button className="hero-cta">Explore Our Journey</button>
        </div>
      </section>

      <section className="company-story">
        <div className="container">
          <div className="story-grid">
            <div className="story-text">
              <h2>Our Story</h2>
              <p >
                Founded in 2008 with a vision to revolutionize digital landscapes, Matrix IT has evolved from a modest startup in Silicon Valley to a global leader in technology solutions. Our journey is defined by relentless innovation, a passion for excellence, and a commitment to empowering our clients.
              </p>
              <p >
                Today, with offices spanning North America, Europe, and Asia, we combine creativity, cutting-edge technology, and strategic insight to deliver transformative results. Our team of experts works tirelessly to bridge the gap between ideas and reality.
              </p>
              <p >
                From developing bespoke software to implementing enterprise-scale digital transformations, Matrixit stands at the forefront of technological advancement, serving industries ranging from healthcare to finance.
              </p>
            </div>
            <div className="story-image">
              <div className="image-placeholder"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="mission-vision">
        <div className="container">
          <div className="mv-grid">
            <div className="mission">
              <h2>Our Mission</h2>
              <p>
                To empower businesses with innovative digital solutions that drive growth and success in the modern world. We leverage cutting-edge technology, data-driven strategies, and a client-centric approach to create lasting impact.
              </p>
            </div>
            <div className="vision">
              <h2>Our Vision</h2>
              <p>
                To be the global leader in digital transformation, setting new benchmarks in technology, service excellence, and sustainability while fostering a culture of innovation and collaboration.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="milestones">
        <div className="container">
          <h2>Key Milestones</h2>
          <div className="milestone-grid">
            <div className="milestone-card">
              <h3>2010</h3>
              <p>Matrixit founded in Silicon Valley</p>
            </div>
            <div className="milestone-card">
              <h3>2015</h3>
              <p>Expanded to Europe and Asia</p>
            </div>
            <div className="milestone-card">
              <h3>2020</h3>
              <p>Launched AI-driven solutions</p>
            </div>
            <div className="milestone-card">
              <h3>2025</h3>
              <p>Serving 1000+ global clients</p>
            </div>
          </div>
        </div>
      </section>

      <section className="team">
        <div className="container">
          <h2>Our Leadership Team</h2>
          <div className="team-grid">
            <div className="team-member">
              <div className="member-image"></div>
              <div className="member-info">
                <h3>John Doe</h3>
                <p className="position">CEO & Founder</p>
                <p>20+ years shaping tech industry</p>
                <p className="bio">Visionary leader driving Matrixit's global success</p>
              </div>
            </div>
            <div className="team-member">
              <div className="member-image"></div>
              <div className="member-info">
                <h3>Jane Smith</h3>
                <p className="position">CTO</p>
                <p>Expert in AI and cloud solutions</p>
                <p className="bio">Architecting our technological future</p>
              </div>
            </div>
            <div className="team-member">
              <div className="member-image"></div>
              <div className="member-info">
                <h3>Mike Johnson</h3>
                <p className="position">Head of Innovation</p>
                <p>Pioneering next-gen solutions</p>
                <p className="bio">Leading R&D for groundbreaking tech</p>
              </div>
            </div>
            <div className="team-member">
              <div className="member-image"></div>
              <div className="member-info">
                <h3>Sarah Williams</h3>
                <p className="position">COO</p>
                <p>Streamlining global operations</p>
                <p className="bio">Ensuring excellence in execution</p>
              </div>
            </div>
            <div className="team-member">
              <div className="member-image"></div>
              <div className="member-info">
                <h3>David Lee</h3>
                <p className="position">CFO</p>
                <p>Financial strategist</p>
                <p className="bio">Guiding fiscal growth and stability</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="values">
        <div className="container">
          <h2>Our Core Values</h2>
          <div className="values-grid">
            <div className="value-card">
              <div className="value-icon">💡</div>
              <h3>Innovation</h3>
              <p>Pushing boundaries and embracing new technologies</p>
            </div>
            <div className="value-card">
              <div className="value-icon">🏆</div>
              <h3>Excellence</h3>
              <p>Delivering the highest quality in everything we do</p>
            </div>
            <div className="value-card">
              <div className="value-icon">🤝</div>
              <h3>Integrity</h3>
              <p>Building trust through honest and ethical practices</p>
            </div>
            <div className="value-card">
              <div className="value-icon">🌍</div>
              <h3>Sustainability</h3>
              <p>Creating solutions for a better tomorrow</p>
            </div>
            <div className="value-card">
              <div className="value-icon">👥</div>
              <h3>Collaboration</h3>
              <p>Fostering teamwork and partnerships</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default About;