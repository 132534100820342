import React from 'react';
import '../assets/styles/contact.css';
import { FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa';
import SEOHelmet from '../components/common/SEOHelmet';

const Contact = () => {
  return (
    <>
      <SEOHelmet
        title="Contact Us"
        description="Get in touch with Matrixit's expert team for technology solutions, support, and partnership opportunities. We're here to help transform your business with innovative IT solutions."
        keywords="contact matrixit, IT support, technology consultation, business solutions, partnership opportunities, customer service"
        canonicalUrl="https://matrixit.com/contact"
        pageType="ContactPage"
      />
      <div className="contact-page">
      <section className="contact-hero">
        <div className="container">
          <h1>Contact Us</h1>
          <p>Get in touch with our team</p>
          <p className="hero-subtitle">
            We're here to assist you with your inquiries, support needs, or partnership opportunities
          </p>
          <button className="hero-cta">Reach Out Now</button>
        </div>
      </section>

      <section className="contact-content">
        <div className="container">
          <div className="contact-grid">
            <div className="contact-info">
              <h2>Contact Information</h2>
              <p className="info-subtitle">
                Connect with us through multiple channels for prompt assistance
              </p>
              <div className="info-grid">
                <div className="info-item">
                  <div className="info-icon">📍</div>
                  <h3>Address</h3>
                  <p>Link Rd,Cuttack</p>
                  <p>Surya Vihar, Cuttack</p>
                  <p>Odisha 753012</p>
                </div>
                <div className="info-item">
                  <div className="info-icon">📞</div>
                  <h3>Phone</h3>
                  <p>+91 (555) 123-4567</p>
                  <p>Toll-Free: 1-800-MATRIX</p>
                </div>
                <div className="info-item">
                  <div className="info-icon">✉️</div>
                  <h3>Email</h3>
                  <p>info@matrixit.com</p>
                  <p>support@matrixit.com</p>
                </div>
                <div className="info-item">
                  <div className="info-icon">⏰</div>
                  <h3>Business Hours</h3>
                  <p>Monday - Friday</p>
                  <p>9:00 AM - 6:00 PM PST</p>
                  <p>24/7 Support Available</p>
                </div>
              </div>
              <div className="social-links">
                <h3>Follow Us</h3>
                <div className="social-grid">
                  <a href="https://twitter.com/matrixit" className="social-link" aria-label="Follow us on Twitter"><FaTwitter /> Twitter</a>
                  <a href="https://linkedin.com/company/matrixit" className="social-link" aria-label="Connect with us on LinkedIn"><FaLinkedin /> LinkedIn</a>
                  <a href="https://facebook.com/matrixit" className="social-link" aria-label="Like us on Facebook"><FaFacebook /> Facebook</a>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <h2>Send us a Message</h2>
              <p className="form-subtitle">
                Fill out the form below, and we’ll get back to you within 24 hours
              </p>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" id="name" name="name" placeholder="Your Name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" name="email" placeholder="Your Email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input type="tel" id="phone" name="phone" placeholder="Your Phone Number" />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" id="subject" name="subject" placeholder="Subject" required />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea id="message" name="message" rows="6" placeholder="Your Message" required></textarea>
                </div>
                <button type="submit" className="submit-button">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-map">
        <div className="container">
          <h2>Find Us</h2>
          <p className="map-subtitle">Visit our headquarters or one of our global offices</p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3738.3485592032553!2d85.88866087472694!3d20.450872207376936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190d74de266903%3A0x93d37e11e7e1388!2sMATRIX%20IT!5e0!3m2!1sen!2sin!4v1742543685861!5m2!1sen!2sin"
            width="100%"
            height="450"
            title="MATRIX IT Headquarters"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </div>
    </>
  );
};

export default Contact;