import React from 'react';
import '../assets/styles/services.css';
import SEOHelmet from '../components/common/SEOHelmet';

const Services = () => {
  return (
    <>
      <SEOHelmet
        title="Our Services"
        description="Comprehensive digital solutions including web development, IT infrastructure, cloud services, cybersecurity, and software development. Transform your business with our expert technology services."
        keywords="IT services, web development, cloud solutions, cybersecurity, software development, IT infrastructure, network security, mobile development, AI solutions"
        canonicalUrl="https://matrixit.com/services"
        pageType="Service"
      />
      <div className="services-page">
      <section className="services-hero">
        <div className="container">
          <h1>Our Services</h1>
          <p>Comprehensive Digital Solutions for Your Business</p>
          <p className="hero-subtitle">
            Tailored technology services to drive innovation, efficiency, and growth
          </p>
          <button className="hero-cta">Get a Quote</button>
        </div>
      </section>

      <section className="services-grid">
        <div className="container">
          <h2>What We Offer</h2>
          <div className="grid">
            {/* Web Development */}
            <div className="service-card">
              <div className="service-icon">🌐</div>
              <h3>Web Development</h3>
              <p>Custom websites and web applications built with cutting-edge technologies to elevate your online presence.</p>
              <ul>
                <li>Responsive Design</li>
                <li>E-commerce Solutions</li>
                <li>Progressive Web Apps</li>
                <li>Content Management Systems</li>
                <li>API Integration</li>
              </ul>
            </div>

            {/* IT Infrastructure & Support */}
            <div className="service-card">
              <div className="service-icon">💻</div>
              <h3>IT Infrastructure & Support</h3>
              <p>Comprehensive IT solutions to ensure seamless business operations and minimize downtime.</p>
              <ul>
                <li>Server Management</li>
                <li>Desktop & Laptop Support</li>
                <li>Hardware & Software Troubleshooting</li>
                <li>IT Consulting & Strategy</li>
                <li>Remote & On-site Support</li>
              </ul>
            </div>

            {/* Cloud Solutions */}
            <div className="service-card">
              <div className="service-icon">☁️</div>
              <h3>Cloud Solutions</h3>
              <p>Secure and scalable cloud services to enhance productivity and collaboration.</p>
              <ul>
                <li>Cloud Migration</li>
                <li>Data Backup & Recovery</li>
                <li>Cloud Storage Solutions</li>
                <li>Virtual Private Servers</li>
                <li>SaaS & PaaS Integration</li>
              </ul>
            </div>

            {/* Network & Security */}
            <div className="service-card">
              <div className="service-icon">🔒</div>
              <h3>Network & Security</h3>
              <p>Protect your business with advanced network and cybersecurity solutions.</p>
              <ul>
                <li>Firewall Implementation</li>
                <li>Data Encryption</li>
                <li>Intrusion Detection Systems</li>
                <li>VPN & Secure Remote Access</li>
                <li>Cybersecurity Audits</li>
              </ul>
            </div>

            {/* Software Development */}
            <div className="service-card">
              <div className="service-icon">🖥️</div>
              <h3>Software Development</h3>
              <p>Tailor-made software solutions for businesses to optimize operations and efficiency.</p>
              <ul>
                <li>Custom Business Applications</li>
                <li>Enterprise Resource Planning (ERP)</li>
                <li>Customer Relationship Management (CRM)</li>
                <li>AI-Powered Solutions</li>
                <li>Mobile & Desktop App Development</li>
              </ul>
            </div>

            {/* AMC (Annual Maintenance Contracts) */}
            <div className="service-card">
              <div className="service-icon">🛠️</div>
              <h3>Annual Maintenance Contracts (AMC)</h3>
              <p>Hassle-free IT maintenance plans to keep your systems running smoothly year-round.</p>
              <ul>
                <li>Preventive Maintenance</li>
                <li>System Performance Optimization</li>
                <li>Emergency IT Support</li>
                <li>Hardware & Software Updates</li>
                <li>Regular Security Checks</li>
              </ul>
            </div>

            {/* Data & Backup Solutions */}
            <div className="service-card">
              <div className="service-icon">💾</div>
              <h3>Data & Backup Solutions</h3>
              <p>Reliable data storage and backup services to prevent data loss and ensure business continuity.</p>
              <ul>
                <li>Automated Cloud Backups</li>
                <li>On-Premise & Hybrid Backup Solutions</li>
                <li>Disaster Recovery Planning</li>
                <li>Secure Data Migration</li>
                <li>Data Compliance & Security</li>
              </ul>
            </div>

            {/* IT Consulting */}
            <div className="service-card">
              <div className="service-icon">📊</div>
              <h3>IT Consulting</h3>
              <p>Expert guidance to help businesses align IT strategies with their long-term goals.</p>
              <ul>
                <li>Technology Roadmap Development</li>
                <li>Cloud & Digital Transformation</li>
                <li>IT Cost Optimization</li>
                <li>Process Automation & Efficiency</li>
                <li>Regulatory Compliance Consulting</li>
              </ul>
            </div>
           
          

            <div className="service-card">
              <div className="service-icon">📱</div>
              <h3>Mobile Development</h3>
              <p>Native and cross-platform mobile applications designed for seamless user experiences</p>
              <ul>
                <li>iOS Development</li>
                <li>Android Development</li>
                <li>React Native Apps</li>
                <li>Mobile UI/UX Design</li>
                <li>App Store Optimization</li>
              </ul>
            </div>
            <div className="service-card">
              <div className="service-icon">🧠</div>
              <h3>Digital Consulting</h3>
              <p>Strategic guidance to navigate digital transformation and achieve business goals</p>
              <ul>
                <li>Technology Assessment</li>
                <li>Digital Strategy</li>
                <li>Process Optimization</li>
                <li>Data Analytics Consulting</li>
                <li>Change Management</li>
              </ul>
            </div>

            <div className="service-card">
              <div className="service-icon">🤖</div>
              <h3>AI & Machine Learning</h3>
              <p>Intelligent solutions to automate processes and unlock data-driven insights</p>
              <ul>
                <li>Predictive Analytics</li>
                <li>Natural Language Processing</li>
                <li>Computer Vision</li>
                <li>AI Model Development</li>
                <li>Chatbot Integration</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="process">
        <div className="container">
          <h2>Our Process</h2>
          <p className="process-subtitle">
            A systematic approach to deliver exceptional results, tailored to your needs
          </p>
          <div className="process-steps">
            <div className="step">
              <div className="step-number">1</div>
              <h3>Discovery</h3>
              <p>Understanding your needs, goals, and challenges through in-depth analysis and stakeholder collaboration</p>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <h3>Planning</h3>
              <p>Developing a strategic roadmap with clear milestones, timelines, and resource allocation</p>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <h3>Execution</h3>
              <p>Implementing solutions with precision, leveraging agile methodologies and best practices</p>
            </div>
            <div className="step">
              <div className="step-number">4</div>
              <h3>Support</h3>
              <p>Providing ongoing maintenance, optimization, and 24/7 support to ensure long-term success</p>
            </div>
          </div>
        </div>
      </section>

      <section className="industries">
        <div className="container">
          <h2>Industries We Serve</h2>
          <div className="industries-grid">
            <div className="industry-card">
              <h3>Healthcare</h3>
              <p>Enhancing patient care with secure, scalable solutions</p>
            </div>
            <div className="industry-card">
              <h3>Finance</h3>
              <p>Streamlining operations with robust financial tech</p>
            </div>
            <div className="industry-card">
              <h3>E-commerce</h3>
              <p>Boosting sales with seamless online platforms</p>
            </div>
            <div className="industry-card">
              <h3>Education</h3>
              <p>Empowering learning with innovative edtech tools</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default Services;