import React from 'react';
import '../assets/styles/products.css';
import SEOHelmet from '../components/common/SEOHelmet';

const Products = () => {
  return (
    <>
      <SEOHelmet
        title="Our Products"
        description="Discover Matrixit's innovative product lineup including enterprise software solutions, hardware products, and digital tools. Find the perfect technology solutions for your business needs."
        keywords="enterprise software, hardware solutions, digital tools, business technology, ERP systems, IT products, technology solutions"
        canonicalUrl="https://matrixit.com/products"
        pageType="Product"
      />
      <div className="products-page">
      <section className="products-hero">
        <div className="container">
          <h1>Our Products</h1>
          <p>Innovative Solutions for Modern Businesses</p>
          <p className="hero-subtitle">
            From cutting-edge software to premium hardware, we empower your success
          </p>
          <button className="hero-cta">Explore Products</button>
        </div>
      </section>

      <section className="product-categories">
        <div className="container">
          <div className="product-category">
            <h2>Enterprise Software Solutions</h2>
            <div className="products-grid">
              <div className="product-card">
                <div className="product-icon">🏢</div>
                <h3>MatrixERP</h3>
                <p>Complete enterprise resource planning solution for streamlined operations</p>
                <ul>
                  <li>Financial Management</li>
                  <li>Inventory Control</li>
                  <li>HR Management</li>
                  <li>Supply Chain Optimization</li>
                  <li>Custom Reporting</li>
                </ul>
              </div>
              <div className="product-card">
                <div className="product-icon">🤝</div>
                <h3>MatrixCRM</h3>
                <p>Customer relationship management platform to boost engagement</p>
                <ul>
                  <li>Lead Management</li>
                  <li>Sales Analytics</li>
                  <li>Customer Support</li>
                  <li>Marketing Automation</li>
                  <li>Mobile Access</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="product-category">
            <h2>Cloud Products</h2>
            <div className="products-grid">
              <div className="product-card">
                <div className="product-icon">☁️</div>
                <h3>MatrixCloud</h3>
                <p>Scalable cloud infrastructure solutions for flexible operations</p>
                <ul>
                  <li>Cloud Storage</li>
                  <li>Cloud Computing</li>
                  <li>Backup Solutions</li>
                  <li>Disaster Recovery</li>
                  <li>Hybrid Cloud Integration</li>
                </ul>
              </div>
              <div className="product-card">
                <div className="product-icon">📊</div>
                <h3>MatrixAnalytics</h3>
                <p>Advanced data analytics platform for actionable insights</p>
                <ul>
                  <li>Business Intelligence</li>
                  <li>Predictive Analytics</li>
                  <li>Real-time Reporting</li>
                  <li>Data Visualization</li>
                  <li>AI-Powered Insights</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="product-category">
            <h2>Hardware Solutions</h2>
            <div className="products-grid">
              <div className="product-card">
                <div className="product-icon">💻</div>
                <h3>MatrixLaptop Pro</h3>
                <p>High-performance laptops for professionals and creators</p>
                <ul>
                  <li>Latest Intel/AMD Processors</li>
                  <li>High-Resolution Display</li>
                  <li>Long-Lasting Battery</li>
                  <li>Customizable RAM & Storage</li>
                  <li>Ergonomic Keyboard</li>
                </ul>
              </div>
              <div className="product-card">
                <div className="product-icon">🖥️</div>
                <h3>MatrixDesktop Elite</h3>
                <p>Powerful desktop systems for enterprise and personal use</p>
                <ul>
                  <li>High-End GPU Support</li>
                  <li>Modular Design</li>
                  <li>Advanced Cooling System</li>
                  <li>Multiple USB Ports</li>
                  <li>Pre-Installed MatrixOS</li>
                </ul>
              </div>
              <div className="product-card">
                <div className="product-icon">⌨️</div>
                <h3>MatrixKeyboard</h3>
                <p>Precision-engineered keyboards for optimal typing</p>
                <ul>
                  <li>Mechanical Switches</li>
                  <li>RGB Backlighting</li>
                  <li>Programmable Keys</li>
                  <li>Anti-Ghosting Technology</li>
                  <li>Wireless Option</li>
                </ul>
              </div>
              <div className="product-card">
                <div className="product-icon">🔋</div>
                <h3>MatrixBattery</h3>
                <p>High-capacity batteries for extended device life</p>
                <ul>
                  <li>Fast Charging Support</li>
                  <li>Long Cycle Life</li>
                  <li>Compatibility with Matrix Devices</li>
                  <li>Overcharge Protection</li>
                  <li>Eco-Friendly Materials</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-features">
        <div className="container">
          <h2>Why Choose Our Products</h2>
          <p className="features-subtitle">
            Designed to deliver performance, reliability, and value
          </p>
          <div className="features-grid">
            <div className="feature">
              <div className="feature-icon">📈</div>
              <h3>Scalable</h3>
              <p>Adaptable solutions that grow with your business needs</p>
            </div>
            <div className="feature">
              <div className="feature-icon">🔒</div>
              <h3>Secure</h3>
              <p>Enterprise-grade security features to protect your data</p>
            </div>
            <div className="feature">
              <div className="feature-icon">🔗</div>
              <h3>Integrated</h3>
              <p>Seamless workflow integration across software and hardware</p>
            </div>
            <div className="feature">
              <div className="feature-icon">🛠️</div>
              <h3>Support</h3>
              <p>24/7 technical assistance and regular updates</p>
            </div>
            <div className="feature">
              <div className="feature-icon">🌿</div>
              <h3>Sustainable</h3>
              <p>Eco-conscious design for a greener future</p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="testimonials">
        <div className="container">
          <h2>Customer Success Stories</h2>
          <div className="testimonials-grid">
            <div className="testimonial-card">
              <p>"MatrixERP transformed our operations, saving us 30% in costs."</p>
              <p className="author">- U3S Engitech</p>
            </div>
            <div className="testimonial-card">
              <p>"The MatrixLaptop Pro is a game-changer for our design team."</p>
              <p className="author">- Sambeet Equipments</p>
            </div>
          </div>
        </div>
      </section> */}
    </div>
    </>
  );
};

export default Products;